<template>
  <div>
    <Card>
      <template #title>Rubricas</template>
      <template #content>
        <DataTable
          class="p-datatable-sm"
          :paginator="true"
          :rows="5"
          stripedRows
          :loading="loading"
          :value="rubricas"
          dataKey="id"
          :filters.sync="filtros"
          :globalFilterFields="['rubrica', 'nome', 'entidade.nome']"
          filterDisplay="menu"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25]"
          currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords} rubricas"
          responsiveLayout="scroll">
          <template #empty> Nenhuma rubrica encontrada. </template>
          <template #loading> Carregando. Por favor aguarde. </template>
          <template #header>
            <span class="mt-2 mr-2 p-input-icon-left">
              <i class="pi pi-search" />
              <InputText
                v-model="filtros['global'].value"
                placeholder="Pesquisar"
                style="width: 100%" />
            </span>
            <Button
              icon="pi pi-filter-slash"
              label="Limpar"
              class="mt-2 mr-2 p-button-outlined"
              @click="limparFiltro" />
            <Button
              label="Novo"
              icon="pi pi-plus"
              class="mt-2 mr-2 p-button-success float-right"
              @click="showFormRubrica()" />
          </template>
          <Column field="rubrica" header="Rubrica"></Column>
          <Column field="nome" header="Nome"></Column>
          <Column field="entidade.nome" header="Entidade"></Column>
          <Column header="Status">
            <template #body="slotProps">
              <Chip
                v-if="slotProps.data.ativo"
                label="ATIVO"
                class="mr-2 mb-2 custom-chip emUso" />
              <Chip
                v-else
                label="INATIVO"
                class="mr-2 mb-2 custom-chip foraUso" />
            </template>
          </Column>
          <Column header="Ações">
            <template #body="slotProps">
              <Button
                v-tooltip.left="'Editar'"
                icon="pi pi-pencil"
                class="p-button-rounded p-button-warning mr-2"
                @click="showFormRubrica(slotProps.data)" />
            </template>
          </Column>
        </DataTable>
      </template>
      <template #footer> </template>
    </Card>
    <RubricaFormAll
      :visible.sync="showRubricaFormDialog"
      :propsrubrica="rubrica"
      :update="update"
      @carregarDados="carregarDados"
      @onUpdate="onUpdateRubrica" />
  </div>
</template>

<script>
import { FilterMatchMode, FilterOperator } from 'primevue/api/'
import RubricaFormAll from './RubricaFormAll.vue'
import RubricaService from '@/service/RubricaService.js'

export default {
  components: {
    RubricaFormAll,
  },
  data() {
    return {
      filtros: {},
      rubricas: [],
      rubrica: {},
      idConsignataria: this.$route.params.idConsignataria,
      loading: false,
      showRubricaFormDialog: false,
      update: false,
    }
  },

  created() {
    this.rubricaService = new RubricaService(this.$http)
    this.initFiltros()
  },

  mounted() {
    this.carregarDados()
  },

  methods: {
    showFormRubrica(data) {
      if (data) {
        this.rubrica = data
        this.update = true
      } else {
        this.rubrica = {}
        this.update = false
      }
      this.showRubricaFormDialog = true
    },

    onUpdateRubrica(event) {
      let index = this.rubricas.findIndex((r) => r.id === event.id)
      index != -1
        ? this.$set(this.rubricas, index, event)
        : this.rubricas.unshift(event)
    },
    carregarDados() {
      this.loading = true
      if (this.idConsignataria != null && this.$auth.hasRoleGestao()) {
        this.rubricaService
          .getRubricasPorConsignatarias(this.idConsignataria)
          .then(
            (res) => {
              this.rubricas = res
              this.loading = false
            },
            (err) => {
              this.loading = false
              this.exibeToast('error', err.response.data.message)
            },
          )
      } else if (
        this.idConsignataria != null &&
        this.$auth.hasRoleGestaoEntidade()
      ) {
        this.rubricaService
          .getListaRubricasUsuarioGestaoEntidadeEConsignatariaId(
            this.idConsignataria,
          )
          .then(
            (res) => {
              this.rubricas = res
              this.loading = false
            },
            (err) => {
              this.loading = false
              this.exibeToast('error', err.response.data.message)
            },
          )
      } else if (
        this.idConsignataria == null &&
        this.$auth.hasRoleGestaoEntidade()
      ) {
        this.rubricaService.getListaRubricasUsuarioGestaoEntidade().then(
          (res) => {
            this.rubricas = res
            this.loading = false
          },
          (err) => {
            this.loading = false
            this.exibeToast('error', err.response.data.message)
          },
        )
      } else {
        this.rubricaService.getListaRubricas().then(
          (res) => {
            this.rubricas = res
            this.loading = false
          },
          (err) => {
            this.loading = false
            this.exibeToast('error', err.response.date.message)
          },
        )
      }
    },

    limparFiltro() {
      this.initFiltros()
    },

    initFiltros() {
      this.filtros = {
        global: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.CONTAINS },
            { value: null, matchMode: FilterMatchMode.EQUALS },
          ],
        },
      }
    },

    addRubrica() {
      if (this.idConsignataria) {
        this.$router.push({ name: 'consignataria-rubrica-nova' })
      } else {
        this.$router.push({ name: 'rubricas-new' })
      }
    },

    editRubrica(data) {
      if (this.idConsignataria) {
        this.$router.push({
          name: 'consignataria-rubrica-editar',
          params: { id: data.id },
        })
      } else {
        this.$router.push({
          name: 'rubricas-edit',
          params: { id: data.id },
        })
      }
    },

    exibeToast(severity, msg) {
      this.$toast.add({
        severity: severity,
        summary: msg,
        life: 10000,
      })
    },
  },
}
</script>

<style>
.foraUso {
  color: #fff;
  background-color: #dc3545;
  font-weight: bold;
  text-align: center;
}
.emUso {
  color: #fff;
  background-color: #28a745;
  font-weight: bold;
  text-align: center;
}
</style>
